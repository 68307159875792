/* istanbul ignore file */
import { info, warn } from '../../util/logger'
import send from '../sendMessage'
import * as Events from './Events'
import * as GA4Events from './GA4Events'
import * as RentalCarEvents from './TealiumEventsCollection/rentalCar'
import {
    trackGA4Purchase,
    trackGA4ShippingInfo,
    trackGA4PaymentInfo,
    trackGA4BeginCheckout,
    trackGA4AddToCart,
    trackGA4RemoveFromCart,
    PURCHASE,
    ADD_SHIPPING_INFO,
    ADD_PAYMENT_INFO,
    ADD_TO_CART,
    REMOVE_FROM_CART,
    BEGIN_CHECKOUT,
} from './GA4Events'
import { initTealium } from './TealiumEventsCollection/utils'

function pushEvent (event) {
    if (!event || !event.event) {
        warn('[Analytics] Event not defined')
        return
    }

    if (!window.dataLayer) {
        warn(`[Analytics] dataLayer not found! for ${event.event}`, event.payload)
        return
    }

    info(`[Analytics] Event triggered: ${event.event}`, event.payload)

    window.dataLayer.push(event)
}

export function trackTealiumEvent (event) {
    event && send(event, window.location)
}

export function trackEvent (event, payload = {}) {
    pushEvent({ event, payload })
}

// Landing parkings
export const trackParkingsChangeType = trackEvent.bind(this, Events.parkingLandingChangeType)
export const trackParkingsChangePlaceEvent = trackEvent.bind(this, Events.parkingLandingChangePlace)
export const trackParkingsChangeStartDateEvent = trackEvent.bind(this, Events.parkingLandingStartDate)
export const trackParkingsChangeEndDateEvent = trackEvent.bind(this, Events.parkingLandingEndDate)
export const trackParkingsSearchEvent = trackEvent.bind(this, Events.parkingLandingSearch)
// Parking search
export const trackParkingSearchChangeDateEvent = trackEvent.bind(this, Events.parkingSearchChangeDate)
export const trackParkingSearchChangePlaceEvent = trackEvent.bind(this, Events.parkingSearchChangePlace)
export const trackParkingSearchItemSelectEvent = trackEvent.bind(this, Events.parkingSearchItemSelect)
export const trackParkingSearchCardSelectEvent = trackEvent.bind(this, Events.parkingSearchCardSelect)
// Parking detail
export const trackParkingDetailChangeDateEvent = trackEvent.bind(this, Events.parkingDetailChangeDate)
export const trackParkingDetailChangePlaceEvent = trackEvent.bind(this, Events.parkingDetailChangePlace)
export const trackParkingDetailSearchEvent = trackEvent.bind(this, Events.parkingDetailSearch)
// Booking process
export const trackBookingProcessChangeDatesEvent = trackEvent.bind(this, Events.bookingProcessChangeDates)
export const trackBookingProcessSelectVehicleEvent = trackEvent.bind(this, Events.bookingProcessSelectVehicle)
export const trackBookingProcessUpdateUserDataEvent = trackEvent.bind(this, Events.bookingProcessUpdateUserData)
export const trackBookingProcessUpdateBookingServicesEvent = trackEvent.bind(this, Events.bookingProcessUpdateBookingServices)
export const trackBookingProcessPayEvent = trackEvent.bind(this, Events.bookingProcessPay)
export const trackBookingProcessConfirmEvent = trackEvent.bind(this, Events.bookingProcessConfirm)
// Season ticket process
export const trackSeasonTicketProcessChangeDateEvent = trackEvent.bind(this, Events.seasonTicketProcessChangeDate)
export const trackSeasonTicketProcessSelectVehicleEvent = trackEvent.bind(this, Events.seasonTicketProcessSelectVehicle)
export const trackSeasonTicketProcessPayEvent = trackEvent.bind(this, Events.seasonTicketProcessPay)
// Wallet
export const trackWalletAddCreditCardEvent = trackEvent.bind(this, Events.walletAddCreditCard)
export const trackWalletCreateDepositEvent = trackEvent.bind(this, Events.walletCreateDeposit)
// Login
export const trackLoginSuccessEvent = trackEvent.bind(this, Events.loginSuccess)
export const trackSignupEvent = trackEvent.bind(this, Events.signup)
// Ser ticket
export const trackSerTicketPayEvent = trackEvent.bind(this, Events.serTicketProcessPay)
// Parking pass
export const trackParkingPassProcessPayEvent = trackEvent.bind(this, Events.parkingPassProcessPay)
// Vehicle Inspection
export const trackVehicleInspectionStationSearchEvent = trackEvent.bind(this, Events.vehicleInspectionLandingSearch)
export const trackVehicleInspectionStationSearchChangePlaceEvent = trackEvent.bind(this, Events.vehicleInspectionLandingChangePlace)
export const trackVehicleInspectionStationSearchChangeVehicleTypeEvent = trackEvent.bind(this, Events.vehicleInspectionLandingChangeVehicleType)
// Electric Charge
export const trackElectricChangeSearchEvent = trackEvent.bind(this, Events.electricChangeSearch)
export const trackElectricChangeSearchChangePlaceEvent = trackEvent.bind(this, Events.electricChangeSearchChangePlace)
export const trackSendContactForm = trackEvent.bind(this, Events.sendContactForm)
// Viat Shop
export const trackViatShopInputPromoCode = trackEvent.bind(this, Events.viatShopInputPromoCode)

export {
    Events,
    GA4Events,
    RentalCarEvents,
    initTealium,
    trackGA4Purchase,
    trackGA4ShippingInfo,
    trackGA4PaymentInfo,
    trackGA4BeginCheckout,
    trackGA4AddToCart,
    trackGA4RemoveFromCart,
    PURCHASE,
    ADD_SHIPPING_INFO,
    ADD_PAYMENT_INFO,
    ADD_TO_CART,
    REMOVE_FROM_CART,
    BEGIN_CHECKOUT,
}
