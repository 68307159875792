/* istanbul ignore file */
/* eslint-disable no-undef */
import { logger as defaultLogger, isWebview } from '@elparking/utils'

const send = (event, uri, isWebView = isWebview(), logger = defaultLogger) => {
    try {
        if (isWebView) {
            window.webkit && window.webkit.messageHandlers.iOSMutua
                ? window.webkit.messageHandlers.iOSMutua.postMessage(event)
                : window.postMessage(event)
        } else {
            window.location !== window.parent.location
                /* istanbul ignore next */
                ? window.parent && uri && window.parent.postMessage(event, uri)
                : window && uri && window.postMessage(event, window.location.origin)
        }
    } catch {
        typeof window !== 'undefined' && logger.warn('message not sended', { event })
    }
}

const sendCloseWebviewEvent = () => {
    send({
        eventId: 'close_webview',
    }, window.location)
}

const sendRentalCarNewContractEvent = () => {
    send({
        eventId: 'rental_car_new_contract',
    }, window.location)
}

const sendPhoneWebviewEvent = (phone) => {
    send({
        eventId: 'phone_call',
        phone: phone,
    }, window.location)
}

const sendBroadcast = (message) => {
    const channel = new BroadcastChannel('broadcast-channel')
    channel.postMessage(message)
}

const sendCheckTOS = () => {
    send({
        eventId: 'check_tos',
    }, window.location)
}

const sendInitProcessEvent = () => {
    send({
        eventId: 'init_process',
    }, window.location)
}

const sendShowInvoicesEvent = () => {
    send({
        eventId: 'show_invoices',
    }, window.location)
}

export {
    sendCloseWebviewEvent,
    sendPhoneWebviewEvent,
    sendInitProcessEvent,
    sendShowInvoicesEvent,
    sendRentalCarNewContractEvent,
    sendCheckTOS,
    sendBroadcast,
}

export default send
