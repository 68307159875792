/* istanbul ignore file */
import {
    trackEvent,
} from './'

export const PURCHASE = 'purchase'
export const ADD_SHIPPING_INFO = 'add_shipping_info'
export const ADD_PAYMENT_INFO = 'add_payment_info'
export const ADD_TO_CART = 'add_to_cart'
export const REMOVE_FROM_CART = 'remove_from_cart'
export const BEGIN_CHECKOUT = 'begin_checkout'

// GA4 Shop
const mapProduct = ({
    id = null,
    name = null,
    units = null,
    priceAmount = null,
    currency = null,
}) => ({
    item_id: id,
    item_name: name,
    quantity: units,
    price: priceAmount,
    currency,
})

export const trackGA4Purchase = ({
    coupon = null,
    currency = null,
    products = null,
    orderId = null,
    shippingAmount = null,
    taxAmount = null,
    priceAmount = null,
}) => trackEvent(
    PURCHASE,
    {
        coupon,
        currency,
        items: products && products.map(mapProduct),
        transaction_id: orderId,
        shipping: shippingAmount,
        tax: taxAmount,
        value: priceAmount,
    })

export const trackGA4ShippingInfo = ({
    coupon = null,
    currency = null,
    products = null,
    priceAmount = null,
}) => trackEvent(
    ADD_SHIPPING_INFO,
    {
        coupon,
        currency,
        items: products && products.map(mapProduct),
        value: priceAmount,
    })

export const trackGA4PaymentInfo = ({
    coupon = null,
    currency = null,
    products = null,
    priceAmount = null,
}) => trackEvent(
    ADD_PAYMENT_INFO,
    {
        coupon,
        currency,
        items: products && products.map(mapProduct),
        value: priceAmount,
    })

export const trackGA4BeginCheckout = ({
    coupon = null,
    currency = null,
    products = null,
    priceAmount = null,
}) => trackEvent(
    BEGIN_CHECKOUT,
    {
        coupon,
        currency,
        items: products && products.map(mapProduct),
        value: priceAmount,
    })

export const trackGA4AddToCart = ({
    products = null,
}) => trackEvent(
    ADD_TO_CART,
    {
        items: products && products.map(mapProduct),
    })

export const trackGA4RemoveFromCart = ({
    products = null,
}) => trackEvent(
    REMOVE_FROM_CART,
    {
        items: products && products.map(mapProduct),
    })
