import { isWebview } from '@elparking/utils'
import { TEALIUM_MUTUAMAS_ACCOUNT, TEALIUM_MUTUAMAS_ENV, TEALIUM_MUTUAMAS_PROFILE } from 'commons/js/constants'
const ANALYTIC_EVENT_LISTENER_NAME = 'analytic-event'

function onComplete (fn, intent = 0) {
    if (window.utag.loader.ended) {
        fn()
        return
    }
    setTimeout(() => {
        onComplete(fn, intent + 1)
    }, 1000)
}

export const getHostDomain = (url) => {
    try {
        const splittedHostname = url.split('.')
        const splittedHost = splittedHostname.slice(-2)
        return '.' + splittedHost.join('.')
    } catch {
        return ''
    }
}

export const initTealium = (onInitComplete = () => {}) => {
    if (isWebview() || window.utag || window.tealiumLoaded) {
        return false
    }
    window.tealiumLoaded = true
    document.cookie = `CONSENTMGR=consent:true%7Cc1:1;domain=${getHostDomain(window.location.host)};path=/`
    window.initTealium && window.initTealium(
        TEALIUM_MUTUAMAS_ACCOUNT,
        TEALIUM_MUTUAMAS_PROFILE,
        TEALIUM_MUTUAMAS_ENV,
        () => {
            onComplete(onInitComplete)
        }
    )
}

const composeActionEvent = (event) => {
    const pageType = isWebview() ? 'app' : 'web'
    const { screenName, ...params } = event
    return {
        eventId: ANALYTIC_EVENT_LISTENER_NAME,
        eventData: {
            type: 'action',
            params: {
                ...params,
                screen_name: `${pageType} ${screenName}`,
            },
            event_name: event.accionEvento,
        },
    }
}

const composeScreenEvent = (event = {}) => {
    const pageType = isWebview() ? 'app' : 'web'
    const {screenName, screenClass, ...params} = event
    return {
        eventId: ANALYTIC_EVENT_LISTENER_NAME,
        eventData: {
            type: 'screen',
            params: {
                ...params,
            },
            screen_name: `${pageType} ${screenName}`,
            screen_class: screenClass,
        },
    }
}

export {
    composeActionEvent,
    composeScreenEvent,
}
